import bsv from 'bsv'
import React, { useCallback, useState } from 'react'
import { useBmap } from '../../context/bmap/BmapProvider'
import { useRelay } from '../../context/relay/RelayProvider'
import { FetchStatus } from '../../types/common'
import BitPic from '../bitpic'
import { Block } from '../block'
import { Button } from '../button'
import * as S from './styles'

// mode should be just view or edit, view by default
const PostForm: React.FC<{
  background?: string
  buttonBackground?: string
  buttonColor?: string
  color?: string
  context: string
  inputBackground?: string
  inputBorder?: string
  inputColor?: string
  mode?: string
  subcontext?: string
  subcontextValue?: string
  placeholder?: string
  value: string
  inputId?: string
  borderRadius?: string
  onSuccess: (txid: string) => any
}> = (props: any) => {
  const [textAreaValue, setTextAreaValue] = useState<string>('')
  const { relay, paymail, authenticate } = useRelay()
  const [busy, setBusy] = useState<boolean>(false)

  const { fetchStatus } = useBmap()

  const handleChange = useCallback((event: any) => {
    setTextAreaValue(event.target.value)
  }, [])

  const submitForm = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()

      if (!textAreaValue || !textAreaValue.length) {
        console.log('no value', textAreaValue)
        return
      }

      // reply or edit mode
      let mode = props.mode || 'view'

      console.log('submit form', textAreaValue, 'context', props.value, relay)

      let data = [
        '19HxigV4QyBv3tHpQVcUEQyq1pzZVdoAut',
        textAreaValue,
        'text/markdown',
        'utf-8',
        '|',
        '1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5'
      ]

      if (mode === 'edit') {
        data = data.concat(['SELECT', props.value])
      }

      data = data.concat([
        'SET',
        'app',
        'metalens',
        'type',
        props.context === 'tx' ? 'reply' : 'post',
        props.context,
        props.value,
        'context',
        props.context
      ])

      if (props.subcontext && props.subcontextValue) {
        data = data.concat([
          'subcontext',
          props.subcontext,
          props.subcontext,
          props.subcontextValue
        ])
      }

      let s = bsv.Script.buildSafeDataOut(data)

      try {
        setBusy(true)
        let res = await relay?.send({
          outputs: [
            {
              amount: '0',
              currency: 'BSV',
              script: s.toASM(),
              signatures: ['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA-BITCOM-HASHED']
            }
          ]
        })
        setTextAreaValue('')
        // TODO: fix this
        // const timeout = setTimeout(() => {
        //   setBusy(false)
        // }, 2000)

        if (res) {
          props.onSuccess(res)
        }
      } catch (e) {
        console.log('e', e)
        setTimeout(() => {
          setBusy(false)
        }, 2000)
      }
    },
    [props, relay, textAreaValue]
  )

  if (fetchStatus === FetchStatus.Loading) {
    return <Block color="#000"></Block>
  }

  if (!paymail) {
    return null
  }

  return (
    <S.PostForm onSubmit={submitForm}>
      <S.Compose>
        <div className="w-8 h-8">
          <BitPic paymail={paymail} />
        </div>

        <form className="w-full max-w-xl bg-white rounded-lg px-4 pt-2">
          <div className="flex flex-wrap mb-6">
            <h2 className="px-4 pt-3 pb-2 text-gray-800 text-lg">
              Add a new comment
            </h2>
            <div className="w-full md:w-full px-3 mb-2 mt-2">
              <S.TextArea
                id={props.inputId}
                name="reply"
                value={textAreaValue}
                onChange={handleChange}
                color={props.inputColor || 'black'}
                background={props.inputBackground || 'white'}
                border={props.inputBorder || '1px solid #eee'}
                placeholder={props.placeholder || 'Write a comment...'}
                required={true}
              />
            </div>
            <div className="w-full md:w-full flex items-start px-3">
              <div className="flex items-start w-1/2 text-gray-700 px-2 mr-auto">
                <S.PoweredBy
                  href="https://metalens.app"
                  rel="noopener noreferrer"
                  target="_blank"
                  color={'#333'}
                >
                  <S.MetaLensLogo />
                  MetaLens
                </S.PoweredBy>
              </div>
              <div className="-mr-1 ml-auto">
                <Button
                  type="submit"
                  background={props.buttonBackground || 'teal'}
                  color={props.buttonColor || '#FFF'}
                  borderRadius={props.borderRadius || '.25rem'}
                  disabled={busy}
                >
                  {props.context !== 'tx' ? 'Post' : 'Reply'}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </S.Compose>
    </S.PostForm>
  )
}

export default PostForm
function timeout(timeout: any) {
  throw new Error('Function not implemented.')
}
