import React, { useState } from 'react'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import { Button } from '../button'

const Details: React.FC<{
  context: string
  subcontext: string
  value: string
}> = (props: any) => {
  const [showDetails, setShowDetails] = useState<Boolean>(false)

  const toggleDetails = (e: React.MouseEvent) => {
    e.stopPropagation()
    setShowDetails(!showDetails)
  }

  return showDetails ? (
    <ScDetails>
      <small>
        {props.context}: {props.value}
        <br />
        {props.subcontext ? `(${props.subcontext})` : null}
      </small>
    </ScDetails>
  ) : (
    <Button
      color={theme.colors.primary}
      onClick={(e: any) => {
        toggleDetails(e)
      }}
    >
      Details
    </Button>
  )
}

export default Details

const ScDetails = styled.div`
  padding: 1rem;
  flex: 4;
`
