import React, { useCallback } from 'react'
import styled from 'styled-components'
import Comment from '../comment'
import Reply from '../reply'
import bmap from 'bmapjs'
import { ROUTES__VIEW } from '../../constants'
import { useBmap } from '../../context/bmap/BmapProvider'
import { theme } from '../../theme/theme'

const Ancestors: React.FC<{ article: bmap.BmapTx }> = (props) => {
  const { articles } = useBmap()

  const ancestors = articles?.filter((a) => {
    return a.tx.h === props.article.MAP.tx
  })

  const goToArticle = useCallback((tx: string) => {
    window.location.href = `${ROUTES__VIEW}/${tx}`
  }, [])

  return (
    <ScAncestors>
      {ancestors?.length ? 'Ancestors' : null}
      {ancestors &&
        ancestors.map((a: bmap.BmapTx) => {
          switch (a.MAP.type) {
            case 'comment':
              return (
                <div key={a._id} onClick={() => goToArticle(a.tx.h)}>
                  <Comment
                    color={theme.colors.grey}
                    border={`1px solid ${theme.colors.grey}`}
                    background={theme.colors.white}
                    article={a}
                  ></Comment>
                </div>
              )
            case 'reply':
              return (
                <div key={a._id} onClick={() => goToArticle(a.tx.h)}>
                  <Reply article={a}></Reply>
                </div>
              )
            default:
              return null
          }
        })}
    </ScAncestors>
  )
}

export default Ancestors

const ScAncestors = styled.div`
  padding: 2rem;
`
