import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps

const Protocol: React.FC<Props> = (Props) => {
  return (
    <div>
      <h1>Protocol</h1>
      MetaLens uses{' '}
      <a
        href="https://bitcoinschema.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        BitcoinSchema
      </a>{' '}
      actions:
      <ul>
        <li>Post</li>
        <li>Follow</li>
        <li>Like</li>
        <li>Repost</li>
        <li>Flag</li>
        <li>Tag</li>
      </ul>
    </div>
  )
}

export default Protocol
