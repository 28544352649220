import { BmapTx } from 'bmapjs'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import BitPic from '../bitpic'
import { Well } from '../well'

interface Props {
  article: BmapTx
  color: string
  background: string
  border: string
}

const Comment: React.FC<Props> = ({
  article,
  color,
  background,
  border
}: Props) => {
  const timeAgo = article.blk
    ? moment(article.blk.t * 1000).fromNow()
    : 'unconfirmed'

  const sigStuff = article['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA']
  if (sigStuff && sigStuff.length === 5) {
    let pm = sigStuff[4].s
    return (
      <ScComment>
        {pm?.length ? (
          <ScUser>
            <BitPic paymail={pm}></BitPic>
          </ScUser>
        ) : null}
        <ScBody>
          <ScTopRow>
            <ScPaymail>{pm}</ScPaymail>
            <ScTimeAgo>
              <a href={`https://whatsonchain/tx/${article}`}>{timeAgo}</a>
            </ScTimeAgo>
          </ScTopRow>

          <br />

          <Well color={color} border={border}>
            {article?.B.content}
          </Well>
          {/* <Actions
            context={props.article.MAP.context}
            subcontext={props.article.MAP.subcontext}
            value={props.article.MAP[props.article.MAP.context]}
          ></Actions> */}
        </ScBody>
      </ScComment>
    )
  }
  return null
}

export default Comment

const ScBody = styled.div`
  padding: 1rem;
  flex: 4;
  position: relative;
`

const ScPaymail = styled.small``
const ScTimeAgo = styled.small``
const ScTopRow = styled.div`
  color: #aaa;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const ScUser = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 70px;
  height: 70px;
  flex-direction: column;
  padding: 0.5rem;
  font-size: 0.8rem;
  box-sizing: border-box;
`

const ScComment = styled.div`
  padding: 1rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
`
