export const ROUTES__VIEW = '/view'
export const ROUTES__FEED = '/feed'
export const ROUTES__PROFILE = '/profile'
export const ROUTES__PROFILE_EDIT = '/edit'
export const ROUTES__LOGIN = '/login'
export const ROUTES__AUTHENTICATE = '/authenticate'
export const ROUTES__URL = '/url'
export const ROUTES__EMBED = '/embed'
export const ROUTES__PROTOCOL = '/protocol'
export const ROUTES__EMBED_AUTH = '/auth'
