import { darken, desaturate } from 'polished'
import styled from 'styled-components'

export const Button = styled.button<{
  color?: string
  background?: string
  borderRadius?: string
}>`
  padding: 0.5rem;
  font-size: 1rem;
  flex: 1;
  color: ${(props: any) => (props.color ? props.color : '#333')};
  background: ${(props: any) => (props.background ? props.background : '#ccc')};
  border-radius: ${(props: any) =>
    props.borderRadius ? props.borderRadius : '.25rem'};
  border: 0;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.25s ease-in-out;
  &:hover {
    background: ${(props) =>
      props.background
        ? desaturate(0.1, darken(0.2, props.background))
        : '#aaa'};
    color: ${(props: any) => (props.color ? darken(0.2, props.color) : '#eee')};
  }
  &:disabled {
    background: ${(props: any) =>
      props.background
        ? desaturate(0.3, darken(0.3, props.background))
        : '#444'};
    color: ${(props: any) => (props.color ? darken(0.3, props.color) : '#aaa')};
  }
`
