import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import {
  ROUTES__FEED,
  ROUTES__PROFILE,
  ROUTES__PROFILE_EDIT
} from '../../constants'
import { useRelay } from '../../context/relay/RelayProvider'
import { useUser } from '../../context/user/UserProvider'

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { user, error } = useUser()
  const { paymail, authenticated, authenticate } = useRelay()
  const [authenticating, setAuthenticating] = useState(true)

  const ensureRelayAuthentication = useCallback(async () => {
    if (!authenticated) {
      await authenticate()
    }
    setAuthenticating(false)
  }, [authenticated, authenticate])

  useEffect(() => {
    ensureRelayAuthentication()
  }, [ensureRelayAuthentication])

  if (authenticating) {
    return null
  }

  if (!authenticated) {
    // User declined to cooperate with relay authentication process
    return <Redirect to={ROUTES__FEED} />
  }

  // Implicitly we know that the code below is authenticated with Relay and has the paymail available
  // Because the paymail is now available, the UserProvider now takes care of fetching the user from the database

  // If we know that the user doesn't exist in the database yet, we redirect him to the edit profile page
  if (error) {
    if (!paymail) {
      // Just to be sure
      return null
    }
    return (
      <Redirect to={`${ROUTES__PROFILE}/${paymail}${ROUTES__PROFILE_EDIT}`} />
    )
  }

  return <Route {...props} />
}

export default PrivateRoute
