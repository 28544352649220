import styled from 'styled-components'
import { theme } from '../../theme/theme'

export const Well = styled.div<{
  bgcolor?: string
  color?: string
  border?: string
}>`
  color: ${(props) => (props.color ? props.color : theme.colors.black)};
  background: ${(props) => (props.bgcolor ? props.bgcolor : 'transparent')};
  padding: 1rem;
  border: ${(props) =>
    props.border ? props.border : `1px solid ${theme.colors.grey}`};
  border-radius: 0.5rem;
  box-sizing: border-box;
`
