import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import Script from 'react-load-script'

export interface RelaySignResult {
  algorithm: 'bitcoin-signed-message'
  key: 'identity'
  data: string // data you passed in
  value: string // signature
}

interface Relay {
  authBeta: () => Promise<string>
  send: (payload: any) => Promise<string>
  quote: (payload: any) => Promise<string>
  sign: (payload: string) => Promise<RelaySignResult>
  isApp: () => boolean
} // TODO: Complete

type ContextValue = {
  relay: Relay | undefined
  paymail: string | undefined
  authenticate: () => Promise<void>
  authenticated: boolean
  ready: boolean
  isApp: boolean
  setPaymail: (paymail: string | undefined) => void
  isLinked: () => boolean
}

interface Props {}

const RelayContext = React.createContext<ContextValue | undefined>(undefined)

const RelayProvider: React.FC<Props> = (props: any) => {
  const [paymail, setPaymail] = useState<string>()
  const [relay, setRelay] = useState<Relay | undefined>()
  const [ready, setReady] = useState<boolean>(false)

  const isApp = useMemo(() => (relay && relay.isApp()) || false, [relay])

  const authenticate = useCallback(async () => {
    if (!relay) {
      throw new Error('Relay script not yet loaded')
    }
    // console.log({ authenticated: !!paymail, relay });

    try {
      const token: string = await relay.authBeta()
      // console.log({ token });
      if (token && !token.hasOwnProperty('error')) {
        const payloadBase64 = token.split('.')[0] // Token structure: "payloadBase64.signature"
        const { paymail: returnedPaymail } = JSON.parse(atob(payloadBase64))
        // localStorage.setItem('paymail', returnedPaymail);
        setPaymail(returnedPaymail)
      } else {
        throw new Error(
          'If you are in private browsing mode try again in a normal browser window. (Relay requires localStorage)'
        )
      }
    } catch (e) {
      throw e
    }
  }, [relay, setPaymail])

  // Auto Authenticate when inside the Relay app
  useEffect(() => {
    if (relay) {
      authenticate()
    }
  }, [authenticate, isApp, relay])

  const handleScriptLoad = useCallback(() => {
    setRelay((window as any)?.relayone)
    setReady(true)
  }, [])

  const value = useMemo(
    () => ({
      relay,
      setPaymail,
      paymail,
      authenticate,
      authenticated: !!paymail,
      ready,
      isApp
    }),
    [relay, setPaymail, paymail, authenticate, ready, isApp]
  )

  return (
    <>
      <Script
        url={`/relayone.js`}
        crossOrigin="anonymous"
        onLoad={handleScriptLoad}
      />
      {/* <Script url={`/scripts/relayotc.js`} crossOrigin="anonymous" onLoad={handleScriptLoad} /> */}
      <RelayContext.Provider value={value} {...props} />
    </>
  )
}

const useRelay = (): ContextValue => {
  const context = useContext(RelayContext)
  if (context === undefined) {
    throw new Error('useRelay must be used within an RelayProvider')
  }
  return context
}

export { RelayProvider, useRelay }
