import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ROUTES__EMBED_AUTH, ROUTES__PROTOCOL } from '../../constants'
import * as S from './styles'

interface Props {}

const Footer: React.FC<Props> = () => {
  const { pathname } = useLocation()

  return pathname !== ROUTES__EMBED_AUTH ? (
    <S.Footer>
      <p>&copy; 2022 MetaLens</p>
      <p>
        <Link to={ROUTES__PROTOCOL}>Protocol</Link>
      </p>
    </S.Footer>
  ) : null
}

export default Footer
