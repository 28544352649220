import bmap from 'bmapjs'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import Comment from '../comment'
import Reply from '../reply'

const Article: React.FC<{ article: bmap.BmapTx }> = (props) => {
  React.useEffect(() => {
    console.log({ props })
  }, [props])

  switch (props.article.MAP.type) {
    case 'post':
    case 'comment':
      return (
        <ScArticle key={props.article._id}>
          <Comment
            color={theme.colors.grey}
            border={`1px solid ${theme.colors.grey}`}
            background={theme.colors.white}
            article={props.article}
          ></Comment>
        </ScArticle>
      )
    case 'reply':
      return (
        <ScArticle key={props.article._id}>
          <Reply article={props.article}></Reply>
        </ScArticle>
      )
    default:
      return null
  }
}

export default Article

const ScArticle = styled.div`
  padding: 2rem;
`
