import React from 'react'
import { Router } from 'react-router'
import { ThemeProvider } from 'styled-components'
import { theme } from '../theme/theme'
import { BmapProvider } from './bmap/BmapProvider'
import { history } from './router/history'
import { UserProvider } from './user/UserProvider'
import { RelayProvider } from './relay/RelayProvider'
import { BitsocketProvider } from './bitsocket/BitsocketProvider'

interface Props {}

export const AppProviders: React.FC<Props> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <Router history={history}>
      <BitsocketProvider>
        <BmapProvider>
          <RelayProvider>
            <UserProvider>{children}</UserProvider>
          </RelayProvider>
        </BmapProvider>
      </BitsocketProvider>
    </Router>
  </ThemeProvider>
)
