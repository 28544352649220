import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import {
  ROUTES__AUTHENTICATE,
  ROUTES__EMBED,
  ROUTES__EMBED_AUTH,
  ROUTES__FEED,
  ROUTES__LOGIN,
  ROUTES__PROFILE,
  ROUTES__PROTOCOL,
  ROUTES__URL,
  ROUTES__VIEW
} from '../../constants'
import Auth from '../auth'
import Embed from '../embed'
import Feed from '../feed'
import Login from '../login'
import Profile from '../profile'
import Protocol from '../protocol'
import View from '../view'
import GoBack from './GoBack'
import PrivateRoute from './PrivateRoute'

interface Props {}

const Routes: React.FC<Props> = () => {
  return (
    <Switch>
      <Route path={ROUTES__FEED} component={Feed} />
      <Route path={`${ROUTES__URL}/:url`} component={View} />
      <Route path={ROUTES__PROTOCOL} component={Protocol} />
      <Route path={ROUTES__EMBED_AUTH} component={Auth} />
      <Route
        path={`${ROUTES__EMBED}/:subcontext/:subcontextValue/:context/:value`}
        component={Embed}
      />
      <Route path={`${ROUTES__EMBED}/:context/:value`} component={Embed} />
      <Route
        path={`${ROUTES__VIEW}${ROUTES__LOGIN}/:sessionId`}
        component={Login}
      />
      <Route path={`${ROUTES__VIEW}/:tx`} component={View} />
      <Route path={`${ROUTES__PROFILE}/:paymail`} component={Profile} />
      <PrivateRoute path={ROUTES__AUTHENTICATE} component={GoBack} />
      <Redirect to={ROUTES__FEED} />
    </Switch>
  )
}

export default Routes
