import React, { useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useRelay } from '../../context/relay/RelayProvider'

type Props = RouteComponentProps<any>

const Auth: React.FC<Props> = (props) => {
  const { paymail, authenticate, isLinked } = useRelay()

  const auth = useCallback(async () => {
    if (!paymail) {
      try {
        await authenticate()
      } catch (e) {
        console.log('couldnt auth', e)
      }
    }
    let linked = await isLinked()
    console.log('close window!!', linked)
    window.close()
  }, [authenticate, paymail, isLinked])

  useEffect(() => {
    auth()
  }, [auth])

  return <div>Authenticating</div>
}

export default Auth
