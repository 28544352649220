import { BmapTx } from 'bmapjs'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { useBmap } from '../../context/bmap/BmapProvider'
import { FetchStatus } from '../../types/common'
import Ancestors from '../ancestors'
import Article from '../article'
import { Block } from '../block'
import Children from '../children'

type Props = RouteComponentProps<{ tx: string }>

const View: React.FC<Props> = ({
  match: {
    params: { tx }
  }
}) => {
  const [article, setArticle] = useState<BmapTx | null>(null)
  const { getArticle, fetchStatus, error } = useBmap()

  useEffect(() => {
    const fire = async () => {
      try {
        const result = await getArticle(tx)
        setArticle(result as BmapTx)
      } catch (e) {
        console.log(e)
      }
    }
    if (fetchStatus === FetchStatus.Idle) {
      fire()
    }
  }, [fetchStatus, getArticle, tx])

  React.useEffect(() => {
    console.log({ article })
  }, [article])

  if (error) {
    return (
      <ScView>
        <ScError>Oops, something went wrong</ScError>
      </ScView>
    )
  }

  if (fetchStatus === FetchStatus.Loading) {
    return (
      <ScView>
        <Block color="black" />
      </ScView>
    )
  }

  if (article) {
    return (
      <ScView>
        <Ancestors article={article}></Ancestors>
        <Article article={article}></Article>
        <br />
        <Children article={article}></Children>
      </ScView>
    )
  }

  return <div>Something went wrong...</div>
}

export default View

const ScError = styled.div`
  color: red;
`

const ScView = styled.div``
