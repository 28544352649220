import styled, { keyframes } from 'styled-components'
import { Bitcoin } from '@styled-icons/foundation/Bitcoin'

const rotateplane = keyframes`
  0% { transform: rotateY(0deg); }
	50% {
		transform: rotateY(-179.9deg);
  }
	100% {
		transform: rotateY(0);
  }
`

export const Block = styled(Bitcoin)<{ color: string }>`
  margin: 100px auto;
  animation: ${rotateplane} 1s infinite ease-in-out;
  width: 1.5rem;
  height: 1.5rem;
  border: ${(props) => `1px solid ${props.color}`};
  color: ${(props) => props.color};
  border-radius: 50%;
  'animation-duration': '1.2s';
`
