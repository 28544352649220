import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../button'
import { Well } from '../well'

interface Props {}

const Lenses: React.FC<Props> = () => {
  const [showLenses, setShowLenses] = useState<boolean>()

  const [top, setTop] = useState<boolean>(true)
  const [url, setUrl] = useState<boolean>(true)
  const [address, setAddress] = useState<boolean>(true)
  const [infohash, setInfohash] = useState<boolean>(true)

  const toggleShowLenses = (e: React.MouseEvent) => {
    e.preventDefault()
    setShowLenses(!showLenses)
  }

  const handleInfohashChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInfohash(e.target.checked)
  }

  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(e.target.checked)
  }

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(e.target.checked)
  }

  const handleTopChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTop(e.target.checked)
  }

  return showLenses ? (
    <ScContainer>
      <Well>
        Lenses (Filter by Context):
        <ScLenses>
          <ScLabel>
            <input
              type="checkbox"
              id="top"
              onChange={(e) => {
                handleTopChange(e)
              }}
              checked={top}
            ></input>{' '}
            none
          </ScLabel>
          <ScLabel>
            <input
              type="checkbox"
              id="url"
              onChange={(e) => {
                handleUrlChange(e)
              }}
              checked={url}
            ></input>{' '}
            url
          </ScLabel>
          <ScLabel>
            <input
              type="checkbox"
              id="address"
              onChange={(e) => {
                handleAddressChange(e)
              }}
              checked={address}
            ></input>{' '}
            address
          </ScLabel>
          <ScLabel>
            <input
              type="checkbox"
              id="infohash"
              checked={infohash}
              onChange={(e) => {
                handleInfohashChange(e)
              }}
            ></input>
            infohash
          </ScLabel>
        </ScLenses>
      </Well>
    </ScContainer>
  ) : (
    <Button
      background="black"
      color="white"
      onClick={(e) => {
        toggleShowLenses(e)
      }}
    >
      Filter
    </Button>
  )
}

export default Lenses

const ScContainer = styled.div`
  padding: 2rem;
  box-sizing: border-box;
`
const ScLenses = styled.form`
  align-items: center;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
`

const ScLabel = styled.label`
  padding: 0.5rem;
`
