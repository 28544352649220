import React from 'react'
import styled from 'styled-components'

const BitPic: React.FC<{ paymail: string; className?: string }> = ({
  paymail,
  className
}) => {
  return (
    <ScImg
      src={`https://bitpic.network/u/${paymail}`}
      className={className || ''}
    ></ScImg>
  )
}

export default BitPic

const ScImg = styled.img`
  border-radius: 50%;
`
