import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Footer from './components/footer'
import Header from './components/header'
import Routes from './components/routes'
import { AppProviders } from './context'

const App = () => (
  <AppProviders>
    {window === window.top ? <Header /> : null}
    <Routes />
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    {window === window.top ? <Footer /> : null}
  </AppProviders>
)

export default App
