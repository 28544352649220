import { EyeIcon, PencilIcon } from '@heroicons/react/solid'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Details from '../details'
import PostForm from '../postform'
import { Well } from '../well'

const Actions: React.FC<{
  context: string
  subcontext: string
  value: string
}> = (props) => {
  const [mode, setMode] = useState<'edit' | 'view' | undefined>()

  const onSuccess = useCallback((txid: string) => {
    toast('🦄 Posted! ' + txid, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }, [])

  const onEditSuccess = useCallback((txid: string) => {
    toast('🦄 Edited! ' + txid, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }, [])

  return (
    <ScContainer>
      <Well border="1px solid #000">
        <ScActions>
          <div onClick={() => setMode('view')}>
            <EyeIcon className="w-4 h-4" />
          </div>
          {mode === 'view' && (
            <PostForm
              context={props.context}
              subcontext={props.subcontext}
              value={props.value}
              mode="view"
              onSuccess={onSuccess}
            ></PostForm>
          )}
          <div onClick={() => setMode('edit')}>
            <PencilIcon className="w-4 h-4" />
          </div>

          {mode === 'edit' && (
            <PostForm
              context={props.context}
              subcontext={props.subcontext}
              value={props.value}
              mode="edit"
              onSuccess={onEditSuccess}
            ></PostForm>
          )}

          <Details
            context={props.context}
            subcontext={props.subcontext}
            value={props.value}
          ></Details>
        </ScActions>
      </Well>
    </ScContainer>
  )
}

export default Actions

const ScContainer = styled.div`
  padding-top: 0.5rem;
  box-sizing: border-box;
`
const ScActions = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
`
