import bmap, { BmapTx } from 'bmapjs'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { FetchStatus } from '../../types/common'
import { useBitsocket } from '../bitsocket/BitsocketProvider'

type ContextValue = {
  articles: BmapTx[] | null
  fetchStatus: FetchStatus
  error: Error | null
  getArticle: (tx: string) => Promise<BmapTx | undefined>
  getRelated: (tx: string) => Promise<BmapTx[] | undefined>
  getComments: (
    context: string,
    value: string,
    subcontext?: string,
    subcontextValue?: string
  ) => void
  getFeed: () => Promise<void>
  comments: (
    context: string,
    value: string,
    subcontext?: string,
    subcontextValue?: string
  ) => BmapTx[]
}

interface Props {}

const BmapContext = React.createContext<ContextValue | undefined>(undefined)

const BmapProvider: React.FC<Props> = (props) => {
  const [articles, setArticles] = useState<BmapTx[] | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(FetchStatus.Idle)

  const saveTx = useCallback(
    async (tx: Object) => {
      let t: BmapTx
      try {
        t = await bmap.TransformTx(tx)
        setArticles([t].concat(articles || []))
      } catch (e) {
        throw new Error('Failed to transform ' + e)
      }
      return t
    },
    [articles]
  )

  const { socket } = useBitsocket()
  socket.onmessage = useCallback(
    (e) => {
      let d = JSON.parse(e.data)
      switch (d.type) {
        case 'open':
          console.log('bitsocket opened')
          break
        case 'push':
          // save latest_block
          d.data.forEach(async (item: Object) => {
            try {
              await saveTx(item)
            } catch (e) {
              // record already exists in the db
            }
          })
          break
      }
    },
    [saveTx]
  )

  const getComments = useCallback(
    (
      context: string,
      value: string,
      subcontext?: string,
      subcontextValue?: string
    ) => {
      async function fetchData() {
        try {
          setFetchStatus(FetchStatus.Loading)
          type findObj = {
            [prop: string]: string
          }
          type projectObj = {
            [prop: string]: number
          }
          type QueryObj = {
            find: findObj
            limit: number
            project: projectObj
          }
          type Q = {
            v: number
            q: QueryObj
          }
          let query: Q = {
            v: 3,
            q: {
              find: {
                'MAP.app': 'metalens',
                'MAP.context': context
              },
              limit: 100,
              project: {
                out: 0,
                in: 0
              }
            }
          }
          let contextField = `MAP.${context}`

          query.q.find[contextField] = value

          let subcontextField = `MAP.${subcontext}`
          if (subcontext && subcontextField && subcontextValue) {
            query.q.find['MAP.subcontext'] = subcontext
            query.q.find[subcontextField] = subcontextValue
          }

          let b64 = btoa(JSON.stringify(query))
          const res = await fetch(`${baseUrl}/q/${b64}`)
          let j = await res.json()
          setFetchStatus(FetchStatus.Success)

          setArticles(j.c.concat(j.u))
        } catch (e) {
          console.error('failed', e)
          setError(e)
          setFetchStatus(FetchStatus.Error)
        }
      }
      fetchData()
    },
    []
  )

  const getFeed = useCallback(async () => {
    try {
      setFetchStatus(FetchStatus.Loading)
      let query = {
        v: 3,
        q: {
          find: {
            'MAP.app': 'metalens'
          },
          limit: 1000,
          project: {
            out: 0,
            in: 0
          }
        }
      }
      let b64 = btoa(JSON.stringify(query))
      const res = await fetch(`${baseUrl}/q/${b64}`)
      let j = await res.json()
      setFetchStatus(FetchStatus.Success)
      setArticles(j.c.concat(j.u))
    } catch (e) {
      console.error('failed', e)
      setError(e)
      setFetchStatus(FetchStatus.Error)
    }
  }, [])

  const getArticle = useCallback(async (tx: string) => {
    try {
      setFetchStatus(FetchStatus.Loading)
      let query = {
        v: 3,
        q: {
          find: {
            'tx.h': tx
          },
          limit: 1000,
          project: {
            out: 0,
            in: 0
          }
        }
      }
      let b64 = btoa(JSON.stringify(query))
      const res = await fetch(`${baseUrl}/q/${b64}`)
      let j = await res.json()
      setFetchStatus(FetchStatus.Success)

      return j.c.concat(j.u).shift()
    } catch (e) {
      console.error('failed', e)
      setError(e)
      setFetchStatus(FetchStatus.Error)
    }
  }, [])

  // const getArticle = useCallback(
  //   (tx: string) => {

  //     return articles?.filter((a) => {
  //       return a.tx.h === tx
  //     })[0]
  //   },
  //   [articles]
  // )

  const comments = useCallback(
    (
      context: string,
      value: string,
      subcontext?: string,
      subcontextValue?: string
    ) => {
      return (
        articles?.filter((a: BmapTx) => {
          let contextMatch =
            a.MAP.context === context && a.MAP[context] === value
          if (a.MAP.subcontext) {
            return contextMatch
              ? a.MAP.subcontext === subcontext &&
                  a.MAP[subcontext] === subcontextValue
              : false
          }
          return contextMatch
        }) || ([] as BmapTx[])
      )
    },
    [articles]
  )

  const getRelated = useCallback(async (startingHash: string) => {
    let query = {
      v: 3,
      q: {
        aggregate: [
          {
            $graphLookup: {
              startWith: startingHash,
              from: { $in: ['c', 'u'] },
              connectFromField: 'MAP.tx',
              connectToField: 'tx.h',
              as: 'relatives',
              maxDepth: 10
            }
          }
        ]
      }
    }

    let b64 = btoa(JSON.stringify(query))

    try {
      let response = await fetch(`${baseUrl}/q/${b64}`)
      console.log('response!', response)
      let j = await response.json()
      return j.c as BmapTx[]
    } catch (e) {
      console.log('e', e)
    }
  }, [])

  const value = useMemo(
    () => ({
      error,
      articles,
      fetchStatus,
      getArticle,
      getRelated,
      comments,
      getFeed,
      getComments
    }),
    [
      error,
      articles,
      fetchStatus,
      getArticle,
      getRelated,
      getFeed,
      comments,
      getComments
    ]
  )

  return <BmapContext.Provider value={value} {...props} />
}

const useBmap = (): ContextValue => {
  const context = useContext(BmapContext)
  if (context === undefined) {
    throw new Error('useBmap must be used within an BmapProvider')
  }
  return context
}

export { BmapProvider, useBmap }

const baseUrl = `https://b.map.sv`
