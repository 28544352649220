import { darken } from 'polished'
import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { normalize, parse } from 'uri-js'
import { useBmap } from '../../context/bmap/BmapProvider'
import { useRelay } from '../../context/relay/RelayProvider'
import { FetchStatus } from '../../types/common'
import { Block } from '../block'
import Comment from '../comment'
import PostForm from '../postform'
import { Card } from './card'
type Props = RouteComponentProps<{
  context: string
  subcontext?: string
  subcontextValue?: string
  value: string
}>

const Embed: React.FC<Props> = ({
  match: {
    params: { context, subcontext, value, subcontextValue }
  }
}) => {
  const { paymail, authenticate, isLinked } = useRelay()
  const { getComments, comments, fetchStatus } = useBmap()

  let c = comments(context, value, subcontext, subcontextValue)
  const params = new URLSearchParams(window.location.search)
  const title = params.get('title')
  const color = params.get('color') || '#FFF'
  const background = params.get('background') || 'transparent'
  const buttonBackground = params.get('buttonBackground') || 'teal'
  const buttonColor = params.get('buttonTextColor') || 'white'
  const bodyBorder = params.get('bodyBorder') || '#ccc'
  const inputBackground = params.get('inputBackground') || 'white'
  const inputColor = params.get('inputColor') || 'black'
  const inputBorder = params.get('inputBorder') || '1px solid pink'

  const [documentHeight, setDocumentHeight] = useState<number | null>(null)
  const [documentWidth, setDocumentWidth] = useState<number | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const onSuccess = useCallback((txid: string) => {
    console.log('send message up', txid)
    // if (window !== window.top) {
    window.parent.postMessage(
      {
        type: 'success',
        txid: txid
      },
      '*'
    )
  }, [])

  const setDimensions = useCallback(() => {
    if (
      !documentHeight ||
      !documentWidth ||
      documentHeight !== document.body.clientHeight ||
      documentWidth !== document.body.clientWidth
    ) {
      setDocumentHeight(document.body.clientHeight)
      setDocumentWidth(document.body.clientWidth)
      window.parent.postMessage(
        {
          type: 'window',
          height: document.body.clientHeight,
          width: document.body.clientWidth,
          href: window.location.href
        },
        '*'
      )
    }
  }, [documentHeight, documentWidth])

  // When window refocuses from authenticating call authenticate to trigger auth in the app
  useEffect(() => {
    document.addEventListener('DOMContentLoaded', () => {
      if (loading) {
        if (!subcontext) {
          getComments(context, value)
        } else {
          getComments(context, value, subcontext, subcontextValue)
        }

        setLoading(false)
        setDimensions()
      }
    })
    window.onfocus = async () => {
      try {
        let linked = await isLinked()
        if (linked) {
          await authenticate()
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, [
    authenticate,
    loading,
    paymail,
    isLinked,
    setDimensions,
    subcontext,
    getComments,
    context,
    value,
    subcontextValue
  ])

  // Whenever number of comments changes post the dimensions
  useEffect(() => {
    if (fetchStatus !== FetchStatus.Loading && c.length) {
      setDimensions()
    }
  }, [c.length, fetchStatus, setDimensions])

  if (fetchStatus === FetchStatus.Loading) {
    return (
      <ScLoading>
        <Block color="#000"></Block>
      </ScLoading>
    )
  }

  const sectionTitle = () => {
    switch (context) {
      case 'url':
        let decodedValue = atob(value)
        const { host, port, path } = parse(normalize(decodedValue))
        let url = host + (port ? ':' + port : '') + path
        return (
          <div>
            Embed {context} {url}
          </div>
        )
      case 'tag':
        return (
          <ScTitle background={background} color={color}>
            {title || value + ' Chat'}
          </ScTitle>
        )
      case 'video':
        return (
          <ScTitle background={background} color={color}>
            {title || 'Comments'}
          </ScTitle>
        )
      default:
        return null
    }
  }

  const focusInput = () => {
    document.getElementById('postFormInput')?.focus()
  }

  const body = () => {
    return (
      <ScComments>
        {c.length ? (
          c.map((comment) => {
            return (
              <Comment
                background={background}
                border={bodyBorder}
                color={color}
                key={comment.tx.h}
                article={comment}
              >
                {comment.B.content}
              </Comment>
            )
          })
        ) : (
          <ScNoComments color={color} onClick={focusInput}>
            No Comments yet...
            <br />
            <br />
          </ScNoComments>
        )}
      </ScComments>
    )
  }

  const postForm = () => {
    return (
      <PostForm
        inputId={'postFormInput'}
        context={context}
        subcontext={subcontext}
        value={value}
        subcontextValue={subcontextValue}
        buttonBackground={buttonBackground}
        buttonColor={buttonColor}
        inputBackground={inputBackground}
        inputColor={inputColor}
        inputBorder={inputBorder}
        onSuccess={onSuccess}
      ></PostForm>
    )
  }

  if (!paymail) {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={'https://metalens-react.web.app/auth'}
      >
        Authenticate
      </a>
    )
  }

  return (
    <Card background={background} color={color}>
      {sectionTitle()}
      {body()}
      {postForm()}
    </Card>
  )
}

export default Embed

const ScTitle = styled.div<{ color: string; background: string }>`
  text-transform: capitalize;
  color: ${(props) => props.color};
  background: ${(props) => props.background};
`

const ScNoComments = styled.div<{ color: string }>`
  padding: 2rem;
  color: ${(props) => darken('.5', props.color)};
  font-size: 0.9rem;
  text-align: center;
  display: none;
`
const ScComments = styled.div``

const ScLoading = styled.div`
  display: flex;
  align-items: center;
`
