import React, { useCallback, useContext, useMemo } from 'react'

type ContextValue = {
  socket: EventSource
}

interface Props {}

const BitsocketContext = React.createContext<ContextValue | undefined>(
  undefined
)

const BitsocketProvider: React.FC<Props> = (props) => {
  const connect = useCallback(() => {
    let query = {
      v: 3,
      q: {
        find: {
          'MAP.app': 'metalens'
        }
      }
    }
    // Base64 encode bitquery
    const b64 = Buffer.from(JSON.stringify(query)).toString('base64')
    // Subscribe
    let surl = 'https://b.map.sv/s/'
    return new EventSource(surl + b64)
  }, [])

  const socket = connect()

  const value = useMemo(
    () => ({
      socket
    }),
    [socket]
  )

  return <BitsocketContext.Provider value={value} {...props} />
}

const useBitsocket = (): ContextValue => {
  const context = useContext(BitsocketContext)
  if (context === undefined) {
    throw new Error('useBitsocket must be used within an BitsocketProvider')
  }
  return context
}

export { BitsocketProvider, useBitsocket }
