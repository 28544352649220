import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { useBmap } from '../../context/bmap/BmapProvider'
import { FetchStatus } from '../../types/common'

type Props = RouteComponentProps<any>

const Login: React.FC<Props> = (props) => {
  const { fetchStatus, error } = useBmap()

  if (error) {
    return (
      <ScLogin>
        <ScError>Oops, something went wrong</ScError>
      </ScLogin>
    )
  }

  if (fetchStatus === FetchStatus.Loading) {
    return (
      <ScLogin>
        <Block color="blue" />
      </ScLogin>
    )
  }

  return (
    <ScLogin>
      <ScTitle>Login</ScTitle>
      Username: <input type="text" name="username" />
      <br />
      Password: <input type="text" name="password" />
    </ScLogin>
  )
}

export default Login

const ScTitle = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: #575757;
  width: 100%;
  margin: 0.5rem 0;
`

// ToDo - consolidate this into a component
const rotateplane = keyframes`
  0% { transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
`

const Block = styled.div<{ color: string }>`
  margin: 100px auto;
  animation: ${rotateplane} 1.2s infinite ease-in-out;
  background: ${(props) => props.color};
  width: 1.5rem;
  height: 1.5rem;
  animation-duration: 1.2s;
`

const ScError = styled.div`
  color: red;
`

const ScLogin = styled.div`
  width: 100%;
  margin: 0.5rem auto;
`
