import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'

const GoBack: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  useEffect(() => {
    history.goBack()
  }, [history])
  return null
}

export default GoBack
