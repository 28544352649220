import { saturate } from 'polished'
import styled from 'styled-components'
import { Bitcoin } from 'styled-icons/fa-brands'
import tw from 'twin.macro'

export const Compose = tw.div`flex mx-auto items-center justify-center mx-8 mb-4 max-w-lg`

export const MetaLensLogo = styled(Bitcoin)`
  margin-right: 0.25rem;
  width: 1rem;
  height: 1rem;
  border: 1px solid #000;
  color: #000;
  border-radius: 50%;
`

export const PostForm = tw.div`
  flex flex-col items-center
`

export const TextArea = styled.textarea<{
  color: string
  background: string
  border: string
}>`
  ${tw`bg-gray-100 rounded border border-gray-400 leading-normal resize-none w-full h-20 py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white`}

  color: ${(props: any) => props.color};
  background: ${(props: any) => props.background};
  border: ${(props: any) => props.border};
  padding: 1rem;
  font-size: 1rem;
  font-family: Roboto;
  flex: 2;
`

// styled.div`
//   display: grid;
//   grid-template-columns: 40px 1fr;
//   grid-gap: 0.5rem;
//   margin-bottom: 0.5rem;
// `

export const PoweredBy = styled.a<{ color: string }>`
  display: flex;
  align-items: center;
  color: ${(props: any) => props.color};
  text-decoration: none;
  font-size: 1rem;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: italic;
  opacity: 0.8;
  &:hover {
    color: ${(props: any) => saturate('0.2', props.color)};
  }
`
