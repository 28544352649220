import React, { useMemo } from 'react'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import {
  ROUTES__AUTHENTICATE,
  ROUTES__EMBED_AUTH,
  ROUTES__FEED,
  ROUTES__PROFILE
} from '../../constants'
import { useRelay } from '../../context/relay/RelayProvider'
import { useUser } from '../../context/user/UserProvider'

interface Props {}

const Header: React.FC<Props> = () => {
  const { pathname } = useLocation()
  const { paymail, authenticated } = useRelay()
  const { user } = useUser()

  const title = useMemo(() => {
    switch (pathname) {
      case ROUTES__FEED:
        return 'MetaLens'
      case `${ROUTES__PROFILE}/${paymail}`:
        return 'Your Profile'
      default:
        return 'MetaLens'
    }
  }, [pathname, paymail])

  return pathname !== ROUTES__EMBED_AUTH ? (
    <ScHeader>
      {pathname !== ROUTES__FEED ? (
        <Link to={ROUTES__FEED}>
          <ScNav>
            <IoMdArrowRoundBack />
            Back
          </ScNav>
        </Link>
      ) : (
        <ScNav />
      )}
      {title === 'MetaLens' ? (
        <ScLogo>
          <ScLogoImg src="/logo.png"></ScLogoImg>
          <ScLogoText>MetaLens</ScLogoText>
        </ScLogo>
      ) : (
        <ScPageTitle>{title}</ScPageTitle>
      )}
      {authenticated && user ? (
        <Link to={`${ROUTES__PROFILE}/${user.paymail}`}>
          <ScBitpic src={`https://bitpic.network/u/${user.paymail}`} />
        </Link>
      ) : (
        <ScLink to={ROUTES__AUTHENTICATE}>Login</ScLink>
      )}
    </ScHeader>
  ) : null
}

export default Header

//
// Utils
//

const ScLogo = styled.div`
  align-items: center;
  display: flex;
`
const ScLogoImg = styled.img`
  max-width: 2.25rem;
  padding-right: 0.5rem;
`

const ScNav = styled.div`
  width: 4rem;
  ${tw`flex items-center`}
`

const ScLink = styled(Link)`
  width: 4rem;
`

const ScPageTitle = styled.div`
  font-weight: bold;
  flex: 4;
  text-align: center;
  text-transform: uppercase;
`

const ScBitpic = styled.img`
  width: 2.5rem;
  border-radius: 50%;
  margin-right: 0.25rem;
`

const ScHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  width: 100%;
  box-sizing: border-box;
`

const ScLogoText = styled.div`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-size: 2.5rem;
`
