import bmap from 'bmapjs'
import React, { useCallback, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Block } from '../../components/block'
import { ROUTES__VIEW } from '../../constants'
import { useBmap } from '../../context/bmap/BmapProvider'
import { theme } from '../../theme/theme'
import { FetchStatus } from '../../types/common'
import Comment from '../comment'
import Lenses from '../lenses'
import Reply from '../reply'

type Props = RouteComponentProps<any>

const Feed: React.FC<Props> = ({ history }: RouteComponentProps<any>) => {
  const { getFeed, articles, fetchStatus, error } = useBmap()

  const goToArticle = useCallback(
    (tx: string) => {
      history.push(`${ROUTES__VIEW}/${tx}`)
    },
    [history]
  )

  useEffect(() => {
    getFeed()
  }, [getFeed])

  if (error) {
    return (
      <ScFeed>
        <ScError>Oops, something went wrong</ScError>
      </ScFeed>
    )
  }

  if (fetchStatus === FetchStatus.Loading) {
    return (
      <ScFeed>
        <Block color="black" />
      </ScFeed>
    )
  }

  const renderArticle = (article: bmap.BmapTx) => {
    switch (article?.MAP?.type) {
      case 'comment':
        return (
          <div key={article._id} onClick={() => goToArticle(article.tx.h)}>
            <Comment
              color={theme.colors.grey}
              border={`1px solid ${theme.colors.grey}`}
              background={theme.colors.white}
              article={article}
            ></Comment>
          </div>
        )
      case 'reply':
        return (
          <div key={article._id} onClick={() => goToArticle(article.tx.h)}>
            <Reply article={article}></Reply>
          </div>
        )
      case 'post':
        return (
          <div key={article._id} onClick={() => goToArticle(article.tx.h)}>
            <Comment
              color={theme.colors.grey}
              border={`1px solid ${theme.colors.grey}`}
              background={theme.colors.white}
              article={article}
            ></Comment>
          </div>
        )
      default:
        return (
          <ScListItem key={article?._id}>
            {article?.MAP.type}
            {article?.B.content}
          </ScListItem>
        )
    }
  }

  if (articles && articles.length > 0) {
    return (
      <ScFeed>
        <Lenses></Lenses>
        <ScTitle>Latest Posts</ScTitle>
        {articles &&
          articles.map((article: bmap.BmapTx) => renderArticle(article))}
      </ScFeed>
    )
  }

  return null
}

export default Feed

const ScTitle = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: #575757;
  width: 100%;
  margin: 0.5rem 0;
`

const ScError = styled.div`
  color: red;
`

const ScListItem = styled.div`
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`

const ScFeed = styled.div`
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;
  max-width: 910px;
  margin: auto;
  ${tw`max-w-3xl text-center`}
`
