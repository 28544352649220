import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Well } from '../well'
import { Button } from '../button'
import { useUser } from '../../context/user/UserProvider'
import BitPic from '../bitpic'

type Props = RouteComponentProps<{ paymail: string }>

const Profile: React.FC<Props> = ({
  match: {
    params: { paymail }
  }
}) => {
  const { user } = useUser()

  const showNotice = () => {
    alert(
      'MetaLens will use TonicPow campaigns to empower you to incentivise new followers. Make sure to follow us on Twitter for announcements.'
    )
  }
  return (
    <ScProfile>
      <ScTitle>Profile</ScTitle>
      <br />
      <ScProfilePic>
        <BitPic paymail={paymail}></BitPic>
      </ScProfilePic>

      <Well>
        <div>
          <label>Paymail:</label>
          {paymail}
        </div>
        <div>
          <label>Username:</label>
          {user?.username}
        </div>
      </Well>
      <br />
      <Button
        onClick={() => {
          showNotice()
        }}
      >
        Incentivise Followers
      </Button>
    </ScProfile>
  )
}

export default Profile

const ScTitle = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: #575757;
  width: 100%;
  margin: 0.5rem 0;
`

const ScProfile = styled.div`
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
`

const ScProfilePic = styled.div`
  width: 200px;
  margin: 1rem auto;
`
