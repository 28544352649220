import { BmapTx } from 'bmapjs'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import React from 'react'
import styled from 'styled-components'
import { theme } from '../../theme/theme'
import Actions from '../actions'
import BitPic from '../bitpic'
import { Well } from '../well'
const Reply: React.FC<{ article: BmapTx }> = (props) => {
  let date = new Date(props.article.blk ? props.article.blk.t * 1000 : 0)
  TimeAgo.addLocale(en)
  const timeAgo = new TimeAgo('en-US')
  let sigStuff = props.article['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA']
  if (sigStuff && sigStuff.length === 5) {
    let pm = sigStuff[4].s
    return (
      <ScReply>
        {pm?.length ? (
          <ScUser>
            <BitPic paymail={pm}></BitPic>
            <br />
            {pm}
          </ScUser>
        ) : null}
        <ScBody>
          Reply to {props.article?.MAP.tx} - {timeAgo.format(date)}
          {props.article?.MAP.url
            ? `URL: ${
                props.article?.MAP.url ? props.article?.MAP.url : null
              }<br />`
            : null}
          <br />
          <br />
          <Well>{props.article?.B.content}</Well>
          <Actions
            context={props.article.MAP.context}
            subcontext={props.article.MAP.subcontext}
            value={props.article.MAP[props.article.MAP.context]}
          />
        </ScBody>
      </ScReply>
    )
  }
  return null
}

export default Reply

const ScBody = styled.div`
  padding: 1rem;
  flex: 4;
`

const ScUser = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  max-width: 70px;
  padding: 0.5rem;
  font-size: 0.8rem;
`

const ScReply = styled.div`
  cursor: pointer;
  border: 1px solid ${theme.colors.grey};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 1rem;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
`
