import React, { useContext, useMemo, useState } from 'react'
import { FetchStatus } from '../../types/common'
// import { useQuery } from 'react-query'
// import specialFetch, { useQueryConfig } from '../../utils/fetch'
import { useRelay } from '../relay/RelayProvider'

export enum UserStatus {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

export type User = {
  avatar: string
  username: string
  paymail?: string
  timestamp: number
}

type ContextValue = {
  user: User | null
  fetchStatus: FetchStatus
  error: Error | null
}

interface Props {}

const UserContext = React.createContext<ContextValue | undefined>(undefined)

const UserProvider: React.FC<Props> = (props) => {
  const { paymail } = useRelay()

  // const { data: user, isFetching, error } = useQuery<User, any>(
  //   paymail ? ['profile', paymail] : null,
  //   () =>
  //     specialFetch(`${process.env.REACT_APP_DOMAIN_PATH}/functions/profile`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ paymail }),
  //     }),
  //   { ...useQueryConfig, retry: false }
  // )
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(FetchStatus.Idle)

  const error = null
  const user = useMemo(
    () =>
      ({
        avatar: 'https://...',
        username: 'Satchmo',
        paymail: paymail,
        timestamp: new Date().getTime() / 1000
      } as User),
    [paymail]
  )

  const value = useMemo(
    () => ({
      user,
      fetchStatus,
      error
    }),
    [user, fetchStatus]
  )

  return <UserContext.Provider value={value} {...props} />
}

const useUser = (): ContextValue => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within an UserProvider')
  }
  return context
}

export { UserProvider, useUser }
